import { mapMediaUrlToPublicFolder } from './utils';
// prepare props for sections on "OUR WORK" page (reused in flooring and painting for now)

// top section props
export const prepareTopSectionProps = topSection => {
  if (!topSection) return {};
  return {
    title: topSection.title,
    subtitle: topSection.subtitle,
    text: topSection.paragraph || '',
  }
}

// plan section props
export const prepareCallToActionProps = callToActionSection => {
  if (!callToActionSection) return {};
  return {
    title: callToActionSection.title,
    desc: callToActionSection.paragraph || '',
    backgroundImage: callToActionSection.media_background?.[0] ? mapMediaUrlToPublicFolder(callToActionSection.media_background?.[0]) : ""
  }
}

export const prepareWorkGallerySectionProps = workGallery => {
  if (!workGallery) return {};
  return {
     images: workGallery.media_assets_list?.length ? workGallery.media_assets_list.map(mapMediaUrlToPublicFolder) : [],
  }
}

// pipeline is our algorithm to map parsed object from sheets
// to props for HomePage component, which will pass this props
// further down to child components, and they will render content
export const ourWorkPipeline = ourWorkPage => {
  const topSection = ourWorkPage['TOP SECTION'] ?? {};
  const workGallery = ourWorkPage['WORK GALLERY'] ?? {};
  const callToActionSection = ourWorkPage['CALL TO ACTION SECTION'] ?? {};
  
  const topSectionProps = prepareTopSectionProps(topSection);

  const workGallerySectionProps = prepareWorkGallerySectionProps(workGallery);

  const introSectionProps = prepareCallToActionProps(callToActionSection);

  return ({
    topSectionProps,
    introSectionProps,
    workGallerySectionProps
  })
}