import React, { Component, useState, createRef, useEffect } from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Slide from "react-reveal/Slide";

import axios from "axios";
import { API_URL } from "../Constants/default";

import Logo from "../Assets/images/bath_logo.svg";

class Footer extends Component {
  static propTypes = {};

  static defaultProps = {
    headerSlideTitle: "Get your free estimate today!!!!",
  };

  state = {
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    email: "",
    phone: "",
    theSource: "",
    isSubmitted: false,
    showBottomForm: false,
  };

  mount = createRef(null);

  componentDidMount() {
    const pn = this.mount.current.parentNode.parentNode;
    pn.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = (e) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    this.setState({
      showBottomForm: winScroll < 70 ? false : true,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  processGetStartedForm = async (e) => {
    if (this.state.isSubmitted === false) {
      // e.preventDefault();

      // Run API submission
      const res = await axios.post(`${API_URL}/customers`, {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        city: this.state.city,
        email: this.state.email,
        phone: this.state.phone,
      });
      // debugger
      // Then update the state to true
      this.setState(
        {
          isSubmitted: true,
        },
        () => {
          // After state is updated, resubmit form
          this.formRef.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      );
    }
  };

  componentWillMount() {
    let vars = {};
    const parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
        if (localStorage) {
          localStorage.setItem("vars", JSON.stringify(vars));
        }
      }
    );

    if ("agmsource" in vars) {
      sessionStorage.setItem("agmsource", vars.agmsource);
    }

    const theSource = localStorage.getItem("vars");
    const parsedSource = theSource && JSON.parse(theSource).agmsource;

    const possibleSources = [
      "1310 news",
      "580 AM",
      "AM900 (Hamilton)",
      "CHCH TV",
      "CHML",
      "CKTB",
      "Company vehicle",
      "CP24",
      "CTV",
      "Home Show",
      "AdWords",
      "Internet Search",
      "Magazine/flier",
      "News Talk 610 (Niagara)",
      "680 News",
      "Referral from previous client (CSR team will collect contact info once connected to the customer)",
      "Social Media",
      "Facebook",
      "Instagram",
      "YouTube",
      "Pinterest",
      "3C Contact Services.",
      "Nexa Call Center",
      "Word of mouth",
    ];

    if (possibleSources.includes(decodeURI(parsedSource))) {
      const decodedURI = decodeURI(parsedSource);
      this.setState({
        theSource: decodedURI,
      });
    }
    // setSource();
  }

  render() {
    const { content } = this.props;
    const { text } = content;
    const isKitchenApp = this.props.isKitchenApp;

    let geoPhone;

    if (isKitchenApp) {
      geoPhone =
        this.props.data == null
          ? "289-201-2608"
          : this.props.data.kitchen_phone;
    } else {
      geoPhone =
        this.props.data == null
          ? "289-201-2608"
          : this.props.data.bathroom_phone;
    }

    let geoEmail =
      this.props.data == null
        ? "service@agmrenovations.com"
        : this.props.data.email;

    let geoLocation =
      this.props.data && this.props.data.location
        ? this.props.data.location
        : [
            {
              title: "Vaughan",
              address: "45 Tigi Court",
              city: "Concord",
              province: "ON",
              postal: "L4K 5E4",
            },
          ];

    let region = "";

    if (sessionStorage.getItem("region")) {
      region = sessionStorage.getItem("region");
    } else {
      region = "";
    }

    let callToActionText = text;
    if (!isKitchenApp) {
      if (region === "calgary" || region === "vancouver") {
        callToActionText = (
          <>
            AGM simplifies the renovation process, making it easy for you to get
            the bathroon of your dreams right now. With our interactive 3D
            Design Creator and ability to provide on-the-spot quotes, we make
            the journey to your new bathroom convenient, fun and most
            importantly, so easy!
          </>
        );
      } else {
        callToActionText = (
          <>
            Try our <u className="blue">3D Creator</u> and get your new bathroom
            designed and built just for you from the comfort of your home.
          </>
        );
      }
    }
    if (isKitchenApp) {
      return (
        <footer ref={this.mount}>
          <div className="container">
            <div className="footer-boxes">
              <div className="first">
                <img src={Logo} alt="AGM Renovations" />
                <p>{callToActionText}</p>
                {/* <Link to="/get-started" className="blue-btn">Start Creating</Link> */}
                <Link to="/get-started" className="blue-btn desktop-btn">
                  Get Free Quote
                </Link>
              </div>
              <div className="secоnd">
                <ul>
                  <li className="address">
                    {geoLocation[0].address}, {geoLocation[0].title}{" "}
                    {geoLocation[0].province} {geoLocation[0].postal}
                  </li>
                  <li className="phone">
                    <a href={"tel:" + geoPhone}>{geoPhone}</a>
                  </li>
                  <li className="email">
                    <a href={"mailto:" + geoEmail}>{geoEmail}</a>
                  </li>
                </ul>
              </div>
              {/* <div className="third">
                <h3>Most <span>Asked Questions</span></h3>
              </div> */}
            </div>
            <div className="footer-bottom">
              <div className="socials">
                <a
                  href="https://www.facebook.com/AGMRenovationsCompany"
                  className="soc-btn soc-btn-f"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
                <a
                  href="https://twitter.com/AGMRenovations"
                  className="soc-btn soc-btn-tw"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Twitter
                </a>
                <a
                  href="https://www.instagram.com/agmrenovationscompany"
                  className="soc-btn soc-btn-ins"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Instagram
                </a>
                <a
                  href="https://www.youtube.com/channel/UCjahxbw8bMUK73g0OJaYm4g"
                  className="soc-btn soc-btn-yt"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  YouTube
                </a>
                <a
                  href="https://www.linkedin.com/company/agm-basements-renovation-finishing/"
                  className="soc-btn soc-btn-in"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </div>
              <p>
                <Link
                  className="realEstateLink__content blue"
                  to="/real-estate"
                >
                  Real Estate Team
                </Link>
                {` `}
                &copy; <span className="year">
                  {new Date().getFullYear()}
                </span>{" "}
                AGM Renovations. All Rights Reserved. |{" "}
                <a href="/privacy">Privacy Policy</a>
                {!isKitchenApp && (
                  <>
                    {" | "}
                    <Link to="/finishes">Catalog</Link>
                  </>
                )}
                {sessionStorage.getItem("countryCode") === "CA" && (
                  <>
                    {" | "}
                    <a
                      className="green"
                      href="https://www.financeit.ca/en/direct/payment-plan/YT0yNDc0MTcmZj0mbD0mcD1adk9IV2NtTk5aTlBnRjVRNGhXLTd3JnM9MCZ0PSZ2PTE=/apply"
                      target="_blank"
                    >
                      Financing
                    </a>
                  </>
                )}
                {" | "}
                <a className="blue" href="/warranty">
                  Warranty
                </a>
              </p>
            </div>
          </div>

          {this.state.showBottomForm ? (
            <Slide top>
              <div className="floating-form bannerPopUp">
                <div className="container">
                  <div className="offer-top">
                    <h2 className="offer-text">
                      {this.props.headerSlideTitle}
                    </h2>{" "}
                    <h2>{geoPhone}</h2>
                  </div>
                  <form
                    ref={(ref) => (this.formRef = ref)}
                    method="POST"
                    id="estimateform"
                    onSubmit={this.processGetStartedForm}
                    action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                  >
                    <div className="form-fields">
                      <div className="float-logo">
                        <a href="/">
                          <img src={Logo} />
                        </a>
                      </div>
                      <input
                        type="hidden"
                        id="oid"
                        name="oid"
                        value="00D5Y000002U1by"
                      />
                      <input
                        type="hidden"
                        name="retURL"
                        id="retURL"
                        value={
                          isKitchenApp
                            ? "https://agmkitchens.com/thank-you"
                            : "https://bathroomrenovations.com/finishes"
                        }
                      />

                      <input
                        type="hidden"
                        name="Lead_Origin__c"
                        id="Lead_Origin__c"
                        value="Web"
                      />

                      {this.state.theSource && (
                        <input
                          id="lead_source"
                          name="lead_source"
                          type="hidden"
                          value={this.state.theSource || ""}
                        />
                      )}

                      <input
                        type="hidden"
                        id="Renovation_Type__c"
                        name="Renovation_Type__c"
                        value={isKitchenApp ? "Kitchen" : "Bathroom"}
                      />

                      <TextField
                        id="first_name"
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="last_name"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <Fab
                        variant="extended"
                        color="primary"
                        className="full-width"
                        type="submit"
                        id="estimateformSubmit"
                      >
                        Continue
                      </Fab>
                    </div>
                  </form>
                </div>
              </div>
            </Slide>
          ) : null}
          <div className="floating-call-btn">
            <a href={"tel:" + geoPhone}>{geoPhone}</a>
          </div>

          <div className="floating-call-btn-mobile">
            <a href={"tel:" + geoPhone} />
          </div>
        </footer>
      );
    } else {
      return (
        <footer ref={this.mount}>
          <div className="container">
            <div className="footer-boxes">
              <div className="first">
                <img src={Logo} alt="AGM Renovations" />
                <p>{callToActionText}</p>
                {/* <Link to="/get-started" className="blue-btn">Start Creating</Link> */}

                {/* <a href="http://bathroomrenovations.com/design" className="blue-btn desktop-btn">
                    GET STARTED
                </a> */}

                <Link to="/get-started" className="blue-btn desktop-btn">
                  Get Free Quote
                </Link>
              </div>
              <div className="secоnd">
                <ul>
                  <li className="address">
                    {geoLocation[0].address}, {geoLocation[0].title}{" "}
                    {geoLocation[0].province} {geoLocation[0].postal}
                  </li>
                  <li className="phone">
                    <a href={"tel:" + geoPhone}>{geoPhone}</a>
                  </li>
                  <li className="email">
                    <a href={"mailto:" + geoEmail}>{geoEmail}</a>
                  </li>
                </ul>
              </div>
              {/* <div className="third">
                <h3>Most <span>Asked Questions</span></h3>
              </div> */}
            </div>
            <div className="footer-bottom">
              <div className="socials">
                <a
                  href="https://www.facebook.com/AGMRenovationsCompany"
                  className="soc-btn soc-btn-f"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
                <a
                  href="https://twitter.com/AGMRenovations"
                  className="soc-btn soc-btn-tw"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Twitter
                </a>
                <a
                  href="https://www.instagram.com/agmrenovationscompany"
                  className="soc-btn soc-btn-ins"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Instagram
                </a>
                <a
                  href="https://www.youtube.com/channel/UCjahxbw8bMUK73g0OJaYm4g"
                  className="soc-btn soc-btn-yt"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  YouTube
                </a>
                <a
                  href="https://www.linkedin.com/company/agm-basements-renovation-finishing/"
                  className="soc-btn soc-btn-in"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </div>
              <p>
                <Link
                  className="realEstateLink__content blue"
                  to="/real-estate"
                >
                  Real Estate Team
                </Link>
                {` `}
                &copy; <span className="year">
                  {new Date().getFullYear()}
                </span>{" "}
                AGM Renovations. All Rights Reserved. |{" "}
                <a href="/privacy">Privacy Policy</a>
                {sessionStorage.getItem("countryCode") === "CA" && (
                  <>
                    {" | "}
                    <a
                      className="green"
                      href="https://www.financeit.ca/en/direct/payment-plan/YT0yNDc0MTcmZj0mbD0mcD1adk9IV2NtTk5aTlBnRjVRNGhXLTd3JnM9MCZ0PSZ2PTE=/apply"
                      target="_blank"
                    >
                      Financing
                    </a>
                  </>
                )}
                {" | "}
                <a className="blue" href="/warranty">
                  Warranty
                </a>
                {/* {!isKitchenApp && (
                  <>
                    {" | "}
                    <Link to="/finishes">Catalog</Link>
                  </>
                )} */}
              </p>
            </div>
          </div>

          {this.state.showBottomForm ? (
            <Slide top>
              <div className="floating-form bannerPopUp">
                <div className="container">
                  <div className="offer-top">
                    <h2 className="offer-text">Get your free quote today!</h2>{" "}
                    <h2>{geoPhone}</h2>
                  </div>
                  <form
                    ref={(ref) => (this.formRef = ref)}
                    method="POST"
                    id="estimateform"
                    action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                    onSubmit={this.processGetStartedForm}
                  >
                    <div className="form-fields">
                      <div className="float-logo">
                        <a href="/">
                          <img src={Logo} />
                        </a>
                      </div>
                      <input
                        type="hidden"
                        id="oid"
                        name="oid"
                        value="00D5Y000002U1by"
                      />
                      <input
                        type="hidden"
                        name="retURL"
                        id="retURL"
                        value={
                          isKitchenApp
                            ? "https://agmkitchens.com/thank-you"
                            : "https://bathroomrenovations.com/thank-you"
                        }
                      />

                      <input
                        type="hidden"
                        name="Lead_Origin__c"
                        id="Lead_Origin__c"
                        value="Web"
                      />

                      {this.state.theSource && (
                        <input
                          id="lead_source"
                          name="lead_source"
                          type="hidden"
                          value={this.state.theSource || ""}
                        />
                      )}

                      <input
                        type="hidden"
                        id="Renovation_Type__c"
                        name="Renovation_Type__c"
                        value={isKitchenApp ? "Kitchen" : "Bathroom"}
                      />

                      <TextField
                        id="first_name"
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="last_name"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <Fab
                        variant="extended"
                        color="primary"
                        className="full-width"
                        type="submit"
                        id="estimateformSubmit"
                      >
                        Continue
                      </Fab>
                    </div>
                  </form>
                </div>
              </div>
            </Slide>
          ) : null}

          <div className="floating-call-btn">
            <a href={"tel:" + geoPhone}>{geoPhone}</a>
          </div>

          <div className="floating-call-btn-mobile">
            <a href={"tel:" + geoPhone} />
          </div>
        </footer>
      );
    }
  }
}

export default Footer;
