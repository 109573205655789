import React from 'react';

export const Enjoy = ({ title, subtitle, imgProps, className }) => {
  // in case we pass subtitle and title separately (after google.sheets)
  // use it separately. If we pass just title, display title
  const mappedTitleWithSubtitle = subtitle ? <><span>{subtitle || ""}</span> {title || ""}</> : title;
  return (
    <>
      <section className={`enjoy ${className || ''}`}>
        <h2>
          {
            mappedTitleWithSubtitle
          }
        </h2>
      </section>
      <div className="enjoyImg">
        <img alt="" {...imgProps} />
      </div>
    </>
  )
}