import React, { useMemo } from 'react';

// export const toStringMapper = (arrayOfStrings) => 

// const DEFAULT_SELECTORS = [
//   {
//     name: 'values',
//     mapper: values => values,
//   },
//   {
//     name: 'text',
//     mapper: toStringMapper,
//   }
// ]

export const withFormattedText = (Component, propsSelector = ['values', 'text']) => {
  const defaultValueParser = (val, index) => {
    if (Array.isArray(val)) {
      return val.map(defaultValueParser);
    }
    else if (typeof val === 'object' && val !== null) {
      const { color, text, fullMatch, ...rest } = val;
      return <span key={index} style={{ color }} {...rest}>{text}</span>
    }
    return val;
  }

  return function _WithFormattedText(props) {

    const deps = propsSelector.map(selector => props[selector]);

    const parsedValues = useMemo(() => {
      return propsSelector.reduce((resultProps, currSelector) => {
        resultProps[currSelector] = defaultValueParser(props[currSelector]);
        return resultProps;
      }, {});
    // because we want to parse again only when prop value for specific selector (e.g "values", "text") changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    const componentProps = {
      ...props,
      ...parsedValues,
    }


    return <Component {...componentProps} />
  }
}

export default withFormattedText;