export const contact_form_ids = {
    form: 'contact-form',

    first_name: 'contact-form_firstname-field',
    last_name:  'contact-form_lastname-field',
    city:  'contact-form_city-field',
    email: 'contact-form_email-field',
    phone: 'contact-form_phone-field',
    project: 'contact-form_project-field',

    submit: 'contact-form_submit-button',
    close:  'contact-form_close-button',

    source: 'contact-lead_source-field',
    origin: 'contact-lead_origin-field',
    renovation: 'contact-lead_renovation-field'
}

export const warranty_form_ids = {
    form: 'warranty-form',

    first_name: 'warranty-form_firstname-field',
    last_name:  'warranty-form_lastname-field',
    city:  'warranty-form_city-field',
    email: 'warranty-form_email-field',
    phone: 'warranty-form_phone-field',

    street_address: 'warranty-form_street_address-field',
    street_address_line_two: 'warranty-form_street_address2-field',
    state_province_region: 'warranty-form_state_province_region-field',
    postal_code: 'warranty-form_postal_code-field',

    submit: 'warranty-form_submit-button',
    close:  'warranty-form_close-button'
}

export const careers_form_ids = {
    form: 'careers-form',

    first_name: 'careers-form_firstname-field',
    last_name:  'careers-form_lastname-field',
    email: 'careers-form_email-field',
    position:  'careers-form_position-field',
    resume_file: 'careers-form_resume_file-field',

    submit: 'careers-form_submit-button',
    close:  'careers-form_close-button'
}
