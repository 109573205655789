import React, { Component } from "react";
import { Link } from "react-router-dom";

import Stations from "../../Common/Stations";
import StationsUS from "../../Common/StationsUS";
import { SliderText } from "./SliderText";
// import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import Laptop from "../../Assets/images/laptop_agm-design-tool.png";
import youtubeLogo from "../../Assets/images/youtube-icon.png";
import LaptopMobile from "../../Assets/images/laptop_agm-design-tool_mobile.png";
import blueCheckmark from "../../Assets/images/checkmark-blue.png";
import whiteLarge from "../../Assets/images/banner-white-background-blob.png";
// import ModalVideo from 'react-modal-video'
import VideoModal from "../../Common/videoModal";
// import ReactPlayer from "react-player";
import { render } from "@testing-library/react";
import withFormattedText from '../../Common/withFormattedText';
import photograph from './../../Assets/images/po.21716763.png'


const DEFAULT_LIST_ITEMS = [
  "Deal with contractors",
  "Invite strangers to your home",
  "Drive to multiple showrooms",
  "And no more wasted time!",
]

const videos = {
  laptopVideo: 's0hz5E1E2ZU',
  HGTV: '5kVBooRtR4Q',
};

class _TopSection extends Component {
  
  constructor (props) {
    super()
    this.state = {
      videoId: videos.laptopVideo,
      isOpen: false,
      countryCode: props.location?.countryCode,
    }
    this.openModal = this.openModal.bind(this)
    this.handleHGTVStar = this.handleHGTVStar.bind(this)
    this.Stantions = null
  }

  componentDidUpdate() {
    const countryCode = this.props.location?.countryCode;
    if (countryCode && this.state.countryCode !== countryCode) this.setState({ countryCode });
  }

  openModal () {
    this.setState({ isOpen: true, videoId: videos.laptopVideo })
  }

  handleHGTVStar () {
    this.setState({ isOpen: true, videoId: videos.HGTV })
  }
  

  render(){
  
    const { 
      title = 'Try Our 3D Creator For FREE!', 
      subtitle = <><span>Select your finishes and get a quote in minutes from the comfort of your own home.</span>It’s so easy!</>, 
      listTitle = "NO NEED TO", listItems = DEFAULT_LIST_ITEMS, backgroundImage, className, children, isKitchenApp, appName 
    } = this.props;

    if (isKitchenApp) {
        return (
          <>
            <section
              className={`home-section modified ${className ? className : ''}`} 
              // style={{ backgroundImage: `url(${backgroundImage})` }}
              style={{ backgroundImage: 'url(/excelAssets/blinds_bg.jpg', backgroundPositionY: '50%' }}
            >
              <div className="banner-cover" />
              <section className="home-banner">
                <div className="welcome-text">

                  {/* {appName === 'painting' ? (
                    <>
                    <h1 className="title_desktop">{title}</h1>
                    <h1 className="title_mobile">Interior Painting</h1>
                    </>
                  ) : (
                    <h1>{title}</h1>
                  )}

                  <p className="subtitle_desktop">{subtitle}</p>
                  <p className="subtitle_mobile">Done Right!</p> */}
                  <h1 className="title_desktop">AGM Blinds</h1>
                  {/* <h4>{listTitle}</h4>
                  <ul>
                    {listItems && listItems.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul> */}
                  <Link to="/get-started" className="blue-btn desktop-btn">Get Free Quote</Link>
                </div>
                { this.state.isOpen && 
                    <VideoModal
                      isOpen={this.state.isOpen}
                      videoId={this.state.videoId}
                      handleClose={ ()=>{ this.setState({ isOpen: false }); } }
                    />
                }
                {/* <div className="footerInfoBar">
                  <div className="itemInfo">
                    <b className="border">Ivan A.</b>
                    <span className="border">CEO AGM Renovations</span>
                  </div>
                  <div onClick={this.handleHGTVStar} className="itemInfo hovered" style={{ margin: '0 30px' }}>
                    <b>Mike Holmes</b>
                    <span  style={{ padding: '0' }}><strong>HGTV</strong> Star & Contractor</span>
                    <hr/>
                  </div>
                </div> */}
              </section>
              {children}
            </section>
            <div className="mobile-visible mobile-visible--no-tablet">
              <Link to="/get-started" className="blue-btn">
                Get Free Quote
                </Link>
            </div>
            {/* {this.Stantions && <this.Stantions />} */}

            {this.state.countryCode === 'CA' 
              ? (<Stations/>)
              : (< StationsUS/>)
            }
          </>
        )
      }
      else {
        return (
          <>
            <section className={`home-section home-section--bathrooms`} style={backgroundImage ? { backgroundImage: `url(${backgroundImage})`} : {}}>
              <section className="home-banner">
                <div className="welcome-text">
                  <h1>{title}</h1>
                  <div className="bottom-items">
                    <span className='bottom-items-subtitle'>
                      {
                        subtitle
                      }
                    </span>
                  </div>
                </div>
  
                { this.state.isOpen && 
                    <VideoModal
                      isOpen={this.state.isOpen}
                      videoId={this.state.videoId}
                      handleClose={ ()=>{ this.setState({ isOpen: false }); } }
                    />
                }
  
                <div className="d-flex checkmark-box">
                  <div className="laptop-box">
                    <div className="desktop-visible">
                      <img onClick={this.openModal} className="youtube-icon" src={youtubeLogo}/>
                      <img onClick={this.openModal} className="laptop-img" src={Laptop}></img>
                    </div>
                    <div className="mobile-visible laptop">
                      <img onClick={this.openModal} className="youtube-icon" src={youtubeLogo}/>
                      <img onClick={this.openModal} className="laptop-img-mobile" src={LaptopMobile}></img>
{/*   
                      <div className="footerInfoBar mobile">
                        <div className="itemInfo">
                          <b className="border">Ivan A.</b>
                          <span className="border">CEO AGM Renovations</span>
                        </div>
                        <div onClick={this.handleHGTVStar} className="itemInfo hovered">
                          <b>Mike Holmes</b>
                          <span><strong>HGTV</strong> Star & Contractor</span>
                          <hr/>
                        </div>
                      </div> */}

                    </div>
                  </div>
                  <div className="right-box">
                    <img src={whiteLarge} className="white-blob"/>
                    <h4>{listTitle}</h4>
                    <ul className="checkmark-list">
                      {listItems && listItems.map((item, index) => (
                        <li className='checkmark-list_item' key={index}>
                          <img src={blueCheckmark} className="float-left"/><b>{item}</b>
                        </li>
                      ))}
                    </ul>
                    {/* <Link to="/design" className="blue-btn desktop-btn">
                      GET STARTED
                    </Link> */}
                     <Link to="/get-started" style={{ display: 'inline-block', marginLeft: 'auto', marginRight: 'auto' }} className="blue-btn desktop-btn">
                      Get Free Quote
                      </Link>
                  </div>
                </div>
  
                {/* <div className="footerInfoBar desktop">
                  <div className="itemInfo">
                    <b className="border">Ivan A.</b>
                    <span className="border">CEO AGM Renovations</span>
                  </div>
                  <div onClick={this.handleHGTVStar} className="itemInfo hovered">
                    <b>Mike Holmes</b>
                    <span><strong>HGTV</strong> Star & Contractor</span>
                      <hr/>
                  </div>
                </div> */}

              </section>
              <img src={photograph} alt="Owner's photograph" className='home-section_photograph' />
            </section>
            <SliderText showButton={true} />
            {this.state.countryCode === 'CA' 
              ? (<Stations/>)
              : (< StationsUS/>)
            }
            {/* {this.Stantions && <this.Stantions />} */}
          </>
        )
      }
  }
}

export default _TopSection;
export const TopSection =  withFormattedText(_TopSection, ['subtitle']);
