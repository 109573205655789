import Axios from "axios";

export class FilesFetcher {

	static OTHER_SERVISES = "OTHER_SERVISES"

	constructor(client) {
		this.client = client;
		this.cache = new Map();
	}

	async fetchOtherServices() {

		const cached = this.cache.get(FilesFetcher.OTHER_SERVISES);

		if (cached) {
			return cached;
		}

		const response = await this.client.get("/otherServices.json");

		const { data: servicesJSON } = response;

		this.cache.set(FilesFetcher.OTHER_SERVISES, servicesJSON);

		return servicesJSON;
	}

}

export default new FilesFetcher(Axios);