import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import station1 from "../Assets/images/Stations/Canada/5d08f0f9f1f8a5247a3c8318580cfra.png";
import station2 from "../Assets/images/Stations/Canada/640toronto_tile.jpg";
import station3 from "../Assets/images/Stations/Canada/680logo-1200x627.png";
import station4 from "../Assets/images/Stations/Canada/940x400_edge 102.1.png";
import station5 from "../Assets/images/Stations/Canada/1200px-1310_NEWS_logo.svg 1310 news.png";
import station6 from "../Assets/images/Stations/Canada/1200px-CHCH2010.png";
import station7 from "../Assets/images/Stations/Canada/CP24_Channel.png";
import station8 from "../Assets/images/Stations/Canada/CTV_flat_logo.png";
import station9 from "../Assets/images/Stations/Canada/KiSS-925-logo-514x514-1.png";
import station10 from "../Assets/images/Stations/Canada/logos_radio_hamilton_900chml.png";
import station11 from "../Assets/images/Stations/Canada/nwAD7PxQTK q107.png";
import station12 from "../Assets/images/Stations/Canada/radio_newstalk610.png";
import station13 from "../Assets/images/Stations/Canada/13gn.png";

const CanadaStations = [
  {className: 'cfra', img: station1},
  {className: 'toronto', img: station2},
  {className: 'stationNews', img: station3},
  {className: 'stationRadio', img: station4},
  {className: 'toronto', img: station5},
  {className: 'chch', img: station6},
  {className: 'cp', img: station7},
  {className: '', img: station8},
  {className: 'kiss', img: station9},
  {className: 'hamilton', img: station10},
  {className: 'Qstation', img: station11},
  {className: 'newsTalk', img: station12},
  {className: 'newsTalk', img: station13}
];


export default function SwipeToSlide() {
  // render() {
    const settings = {
      className: "",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 8,
      slidesToScroll: 4,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      cssEase: "linear",
      arrows: false,
      responsive: [
        {
          breakpoint: 1525,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 4,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            cssEase: "linear",
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3000,
            cssEase: "linear",
          },
        },
      ],
      afterChange: function (index) {},
    };
    return (
      <div className="stationSlider">
        <h4 style={{ textAlign: "center", margin: "0", color: "grey" }}>
          {" "}
          AS FEATURED ON
        </h4>
        <Slider {...settings} style={{marginTop: '-20px'}}>
          {CanadaStations.map( (station, index) => (
            <div key={index} className={`stationImg ${station.className}`}>
              <img src={station.img} />
            </div>
          ))}
        </Slider>
      </div>
    );
  // }
}
