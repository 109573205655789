import React, { useEffect, useState } from 'react';
import {
  TopSection,
  Intro,
  HowItWorks,
  Testimonials,
  OurAwards,
  LatestWorks,
  CallToAction,
} from '../../Containers/Home';

import sectionBackground from '../../Assets/images/batherombg.jpg';
import po from '../../Assets/images/po.png';
import hiwImg1 from '../../Assets/images/bathroom_drawing.png';
import hiwImg2 from "../../Assets/images/select_samples.png";
import hiwImg3 from '../../Assets/images/construction_begins.png';

import LatestWorkOne from "../../Assets/images/BathroomGallery/6.jpg";
import LatestWorkTwo from "../../Assets/images/BathroomGallery/dfgr.jpg";
import LatestWorkThree from "../../Assets/images/BathroomGallery/BathReno25.d2eda50f.jpg";
import LatestWorkFour from "../../Assets/images/BathroomGallery/fer.jpg";
import LatestWorkFive from "../../Assets/images/BathroomGallery/se.jpg";

export const HomePage = (props) => {
  const { content, PROJECT_TYPE } = props;
  // const topSectionProps = {
  //   title: 'Bathrooms Made Simple',
  //   backgroundImage: sectionBackground,
  // }
  const {
    topSectionProps = {},
    howItWorksSectionProps = {},
    callToActionSectionProps = {},
    googleReviewsSectionProps = {},
    introSectionProps = {},
    latestWorkSectionProps = {},
  } = content;

  /*
  const topSectionProps = {
    className: 'home-section--kitchen',
    title: 'Residential & Commercial Interior Painting Services',
    subtitle: 'We take care of all the prep work, painting and after job cleaning. Get an instant quote.',
    // listTitle: 'Hassle Free Quote without:',
    // listItems: ['Dealing with contractors', 'Inviting strangers to your home', 'Visiting multiple showrooms', 'Wasting time!'],
    backgroundImage: sectionBackground,
  }
  */

 
  /*const howItWorksItems = [
    {
      title: 'Schedule an Estimate:',
      text: 'Contact us to schedule an estimate for your interior painting job. We will supply you with a quote.',
      useBackgroundImage: true,
      imgProps: {
        src: hiwImg1,
      },
    },
    {
      title: 'Select Your Paint Colours:',
      text: 'After reviewing and approving your quote, our team will help you select the paint colours.',
      useBackgroundImage: true,
      imgProps: {
        src: hiwImg2,
      },
    },
    {
      title: 'Painting:',
      text: 'We handle prep work which also includes covering and protecting floors, patching all wall imperfections with premium self-priming compound and sanding walls. Clean up is also taken care of after the job is done.',
      imgProps: {
        src: hiwImg3,
      },
    },
  ];*/

  // const latestWorksItems = [
  //   LatestWorkOne,
  //   LatestWorkTwo,
  //   LatestWorkThree,
  //   LatestWorkFour,
  //   LatestWorkFive,
  // ];

  /*const callToActionProps = {
    text: 'Your free consultation and quote on your bathroom design and renovation project is only a quick phone call away.',
    subtitle: "Choosing AGM Renovations means guaranteed...",
    backgroundImage: callToActionBackground,
    desc: <>Try our <u className="blue">3D Creator</u> and get your new bathroom designed and built just for you from
            the comfort of your home.</>
  };
  */

  /*
  const testimonialsItems = [
    {
      text: `I decided to work with AGM after going through their sales process which was very professional, insightful, and very very detailed. This gave me the confidence over contractors who were just randomly quoting rates without giving a clear breakdown of their services. Our project was professionally handled, and kudos to Lina for her exceptional project management throughout. I did not have to monitor or ask for progress or be involved in everyday activities. All activities were completed on time and with high quality.`,
      name: 'K.T. Addo',
    },
    {
      text: `Simply above and beyond! Surpassed any expectation I had. The AGM team is great. Our Project manager Evaldi was amazing! Trades and everyone involved were friendly, polite, and professional. Honestly selecting AGM was the best decision we made. The project was done efficiently, completed in a timely manner and was a high-quality result.`,
      name: 'Carm Ragno',
    },
    {
      text: `Sergei was an absolute pleasure to deal with! He was extremely responsive and paid attention to every detail! He ensured that everything was done in a timely manner and to our satisfaction. Anytime I had any questions he would respond immediately. All of the workers that came for each step were also extremely professional and kind.`,
      name: 'Josie Vierra',
    }
  ]
  */

  let region = sessionStorage.getItem("region") ? sessionStorage.region : '';

  let dynamicIntroSectionProps = introSectionProps;

  if (region === 'calgary' || region === 'vancouver') {
    dynamicIntroSectionProps = {
      title: "Backed by decades of experience.",
      subtitle: "Expert bathroom renovations since 1998.",
      text: "In Canada, around 97.5% of renovation projects are either finished late or over budget. Only 2.5% of projects completed properly. As the oldest company in the industry, we are proudly part of this elite group, with a proven track record of finishing bathroom renovations on time and on budget."
    }
  }

  if (props.REACT_APP_NAME === 'bathrooms' || props.REACT_APP_NAME === 'kitchens') {
    dynamicIntroSectionProps.showPreviewVideo = true;
  }

  const { isKitchenApp } = props;

  let howItWorksClassName = '';
  let callToActionsClassName = '';
  if (props.REACT_APP_NAME === 'painting') {
    howItWorksClassName = 'how-it-works-section--painting';
    callToActionsClassName = 'call-to-action-section--painting';
  }
  else if (props.REACT_APP_NAME === 'kitchens') {
    howItWorksClassName = 'how-it-works-section--kitchens';
  }

  const appName = props.REACT_APP_NAME === 'painting' ? 'flooring' : props.REACT_APP_NAME === 'flooring' ? 'flooring' : '';

  return (
    <>
      <TopSection isKitchenApp={isKitchenApp} {...({...topSectionProps, className: `home-section--kitchen ${appName}`, appName: props.REACT_APP_NAME })} location={props.location}>
      </TopSection>
      <Intro {...dynamicIntroSectionProps} />
      <HowItWorks className={howItWorksClassName} {...howItWorksSectionProps} />
      <Testimonials {...googleReviewsSectionProps} />
      <OurAwards />
      <LatestWorks {...latestWorkSectionProps} />
      <CallToAction isKitchenApp={isKitchenApp} siteName={PROJECT_TYPE} className={callToActionsClassName} {...callToActionSectionProps} />
    </>
  )
}