import React from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";



import { sliderSettings } from './constants';

export const LatestWorks = ({ items, title = 'Our Latest Work' }) => {
  return (
    <section className="latest-works">
      <h1>{title}</h1>
      <div className="works-slider">
        <Slider {...{...sliderSettings, autoplaySpeed: 2000, autoplay: true}}>
          {items.map((item, index) => (
            <div key={index} className="latest-work-slider-item">
              <img src={item} alt="latest work" />
            </div>
          ))}
        </Slider>
      </div>
      <div className="viewMoreContainer">
        <Link to="/our-work" className="blue-btn">
          View More
        </Link>
      </div>
    </section>
  )
}