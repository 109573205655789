import { GoogleSpreadsheet } from 'google-spreadsheet';
import config from './../agm-dynamic-content-40d5a17346ce.json';
import { aboutUsPipeline } from './aboutPageSectionProps';
import { homePagePipeline } from './homePageSectionProps';
import { howItWorksPipeline } from './howItWorksPageProps';
import { ourWorkPipeline } from './ourWorkPageSectionProps';
import { parseMultilineToArray } from './utils';

const defaultMapper = value => value ?? "";

const arrayMapper = parseMultilineToArray;

const formattedTextMapper = text => {
  if (text === undefined) {
    return "";
  };
  const regularText = text.split(/<<.+>>\(#?[\w\d]+\)/);
  if (regularText.length <= 1) return text;
  const match = String(text).match(CUSTOM_COLOR_REGEXP);
  const result = [];
  if (match?.length) {
    match.forEach((coloredText, index) => {
      const match = String(coloredText).match(new RegExp(CUSTOM_COLOR_REGEXP, ''));
      if (!match) return
      const [fullMatch, text, color] = match;
      const prev = regularText[index];
      const next = regularText[index + 1];
      if (prev) {
        result.push(prev)
      }
      result.push({
        fullMatch,
        text: text.trim(),
        color,
      })
      if (next) {
        result.push(next)
      }
    })
    return result;
  }
  else return text;
}

const arrayMapperWithColoredText = val => arrayMapper(val).map(formattedTextMapper);

const paragraphParser = value => {
  if (!value) return "";
  const normalizedValue = value?.length ? String(value).replace(/__/gi, "") : "";
  let phoneNumber = DEFAULT_PHONE_NUMBER
  if (typeof window !== 'undefined') {
    const relevantPhoneNumberNode = document.querySelector('.floating-call-btn a');
    if (relevantPhoneNumberNode && relevantPhoneNumberNode.textContent) {
      phoneNumber = relevantPhoneNumberNode.textContent;
    }
  }

  return normalizedValue.replace(PHONE_REPLACE_REGEXP, `<<${phoneNumber}>>(#00b1e3)`);
}

const DEFAULT_PHONE_NUMBER = "617-401-0469";

const PHONE_REPLACE_REGEXP = /<?insert_phone_number>?/gi;

const CUSTOM_COLOR_REGEXP = /<<(.+)>>\((#?[\w\d]+)\)/gi;

// dictionary to map cells from google.docs to content that is taken by our components
// key is name of column in google.docs
// value is an object, where "mappedKey" is a name we use in our components
// and a mapper to map string value to something different, e.g an array.
export const CELL_NAMES_DICTIONARY = {
  'Title': {
    mappedKey: 'title',
    mapper: defaultMapper,
  },
  'Sub-Title': {
    mappedKey: 'subtitle',
    mapper: formattedTextMapper,
  },
  'Paragrapth': {
    mappedKey: 'paragraph',
    mapper: paragraph => formattedTextMapper(paragraphParser(paragraph)),
  },
  'Media Background': {
    mappedKey: 'media_background',
    mapper: value => value ? value.includes("__") ? parseMultilineToArray(value) : [value] : [],
  },
  'Points Title': {
    mappedKey: 'points_title',
    mapper: defaultMapper,
  },
  'Points Sub-Title List': {
    mappedKey: 'points_subtitle_list',
    mapper: arrayMapper
  },
  'Points Paragraph List': {
    mappedKey: 'points_paragraph_list',
    mapper: arrayMapperWithColoredText,
  },
  'Media Assets List': {
    mappedKey: 'media_assets_list',
    mapper: media => media?.length ? parseMultilineToArray(media).map(pathToMediaFile => typeof pathToMediaFile === 'string' ? pathToMediaFile.replace(/[\r\n]/gi, '') : '') : [],
  }
};

export const parseGenericValuesObject = async worksheet => {
  try {
    const rows = await worksheet.getRows();
    // await worksheet.loadCells();
    // const sectionNames = rows.map(row => homeSheet.getCell(row.rowIndex, 0)?.value).filter(sectionName => !!sectionName);
    const rowsWithCellValues = rows.filter(row => row?._rawData?.length > 0).reduce((rowsAcc, row) => {
      const { _rawData } = row;

      const contents = Object.keys(CELL_NAMES_DICTIONARY).reduce((acc, cellNameInSheets, cellIndex) => {
        const { mappedKey, mapper } = CELL_NAMES_DICTIONARY[cellNameInSheets];
        // const cell = worksheet.getCell(row.rowIndex - 1, cellIndex + 1);
        acc[mappedKey] = mapper(row[cellNameInSheets]);
        return acc;
      }, {})
      
      const sectionName = row['Section Name'] || _rawData[0]

      rowsAcc = {
        ...rowsAcc,
        [sectionName]: {
          ...contents,
          sectionName,
          rawData: _rawData
        }
      }

      return rowsAcc;
    }, {})
    return rowsWithCellValues;
  } catch (error) {
    console.error(`Error parsing rows for worksheet: ${worksheet}, ${error}`);
    return {};
  }
}

// gets worksheet from google.docs (separate tab), extracts rows from it and creates parsed object with sections and keys
// and values as content (cells)
export const parseContentsForPage = (genericObject = {}, pipelineName) => {
  let mappedContents = {
    ...genericObject,
  }
  /*
  Object.keys(genericObject).forEach(pageName => {
    const pageGenericContents = genericObject[pageName];
    switch(pageName) {
      case "HOME": {
        mappedContents[pageName] = homePagePipeline(pageGenericContents);
        console.log('home mapped contents', mappedContents);
        break;
      }
      default: {
        // TODO: maybe implement generic pipeline for every other page
      }
    }
  })
  */
  switch(pipelineName) {
    case "HOME": {
      mappedContents = homePagePipeline(genericObject);
      break;
    }
    case "HOW IT WORKS": {
      mappedContents = howItWorksPipeline(genericObject);
      break;
    }
    case "OUR WORK": {
      mappedContents = ourWorkPipeline(genericObject);
      break;
    }
    case "ABOUT US": {
      mappedContents = aboutUsPipeline(genericObject);
      break;
    }
    default: {
      // TODO: maybe implement generic pipeline for every other page
    }
  }

  return mappedContents;
}

const loadSpreadsheets = async (docId) => {
  // Initialize the sheet - doc ID is the long id in the sheets URL
  const doc = new GoogleSpreadsheet(docId);
  try {
    // Initialize Auth - see https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
    await doc.useServiceAccountAuth({
      // env var values are copied from service account credentials generated by google
      // see "Authentication" section in docs for more info
      client_email: config.client_email,
      private_key: config.private_key,
    });
    await doc.loadInfo(); // loads document properties and worksheets
    const worksheets = doc.sheetsByTitle;
    return worksheets;
  } catch (error) {
    console.error(`Error loading spreadsheets from google.docs: ${error}`);
  }
}

export default loadSpreadsheets;