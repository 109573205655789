import React, { useState } from 'react'
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/bath_logo.svg";
import VideoPreview from './VideoPreview';

export const Intro = (props) => {

    const {
      title = 'Backed by decades of experience.',
      subtitle = 'Expert bathroom renovations since 1998.',
      text = 'In Canada, around 97.5% of renovation projects are either finished late or over budget. Only 2.5% of projects completed properly. As the oldest company in the industry, we are proudly part of this elite group, with a proven track record of finishing bathroom renovations on time and on budget.',
      isKitchenApp
    } = props;

    
  return (
    <section className="home-intro">
      <div className="container">
        <div className="top-cnt">
          { props.showPreviewVideo 
            ? (<VideoPreview countryCode={props.countryCode} />)
            : (<span className="logo"><img src={Logo} alt="AGM Renovations" /></span>)
          }
          <h2>{title}</h2>
          <h3 className="quicksand">{subtitle}</h3>
        </div>
        <p style={{ whiteSpace: "pre-line" }}>{text}</p>
        <a href={(isKitchenApp || true) ? "/get-started" : "/design"} className="blue-btn">
          GET STARTED
        </a>
      </div>
    </section>
  )
}
