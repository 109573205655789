import { getImageByIndex, mapMediaUrlToPublicFolder, parseMultilineToArray, safeArray } from './utils';

// top section props
export const prepareTopSectionProps = topSection => {
  if (!topSection) return {};
  return ({
    title: topSection.title,
    subtitle: topSection.subtitle,
    bannerImgProps: {
      alt: 'laptop with kitchen on the screen',
      src: mapMediaUrlToPublicFolder(topSection.media_background?.[0]),
    },
  })
}

// plan section props
export const preparePlanSectionProps = planSection => {
  if (!planSection) return {};
  return safeArray(planSection.points_paragraph_list).map((paragraph, index) => {
    const title = planSection.points_subtitle_list?.length ? planSection.points_subtitle_list[index] : "";
    const image = planSection.media_assets_list?.length ? planSection.media_assets_list[index] : "";
    return ({
      title,
      description: paragraph,
      imgProps: {
        src: mapMediaUrlToPublicFolder(image),
      }
    })
  })
}

export const prepare3dCreatorItems = creatorSection => {
  if (!creatorSection) return {};
  return safeArray(creatorSection.points_paragraph_list).map((paragraph, index) => {
    const title = creatorSection.points_subtitle_list?.length ? creatorSection.points_subtitle_list[index] : "";
    const image = creatorSection.media_assets_list?.length ? creatorSection.media_assets_list[index] : "";
    return ({
      title,
      description: paragraph,
      imgProps: {
        src: mapMediaUrlToPublicFolder(image),
      }
    })
  })
}

// pipeline is our algorithm to map parsed object from sheets
// to props for page component, which will pass this props
// further down to child components, and they will render content
export const howItWorksPipeline = sheetsHowItWorksPage => {
  const topSection = sheetsHowItWorksPage['TOP SECTION'] ?? {};
  const planSection = sheetsHowItWorksPage['PLAN SECTION'] ?? {};
  const creatorSection = sheetsHowItWorksPage['3D CREATOR SECTION'] ?? {};
  const callToActionSection = sheetsHowItWorksPage['CALL TO ACTION SECTION'] ?? {};
  
  const topSectionProps = prepareTopSectionProps(topSection);

  const workStepsItems = preparePlanSectionProps(planSection);

  const workStepsSectionProps = {
    items: workStepsItems,
  }

  const creatorSectionProps = {
    title: creatorSection.title,
    subtitle: creatorSection.subtitle,
    items: prepare3dCreatorItems(creatorSection)
  }

  const enjoySectionProps = {
    subtitle: callToActionSection.subtitle,
    title: callToActionSection.title,
    imgProps: {
      src: mapMediaUrlToPublicFolder(callToActionSection.media_background?.[0]),
      alt: 'kitchen',
    }
  }

  return ({
    topSectionProps,
    workStepsSectionProps,
    creatorSectionProps: creatorSectionProps,
    enjoySectionProps
  })
}