import React from 'react';
import { Link } from "react-router-dom";

import bath_1 from "../../Assets/images/bath_1.png";

export const Intro = (props) => {
  
  const { 
    desc,
    title = 'Feeling inspired?',
    backgroundImage,
    className
  } = props;

  let region = '';
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = ''
  }

  const renderButton = () =>{    
    const isKitchenApp = process.env.REACT_APP_NAME !== 'bathrooms';

    // if(region==='vancouver' || region ==='calgary'){
    if(isKitchenApp || true) {
      return <Link to="/get-started" className="blue-btn margin-auto">Get Started</Link>
    } else {
      return <a href="/design" className="blue-btn margin-auto-mobile">GET STARTED</a>
    }
  }

  return (
    <section className={`works-intro ${className || ''}`}>
      <div className="container">
        <div className="cnt">
          <div className="text">
            <div className="text-cnt text-lft">
              {/* <h3>A 22+ Year Commitment to Quality.</h3>
                  <p>On-time, under-budget bathroom renovations.</p>
                  <div className="blue-text">Decades of proven results.</div> */}
              <h3>{title}</h3>
              <p>
                {desc}
              </p>
              { renderButton() }
            </div>
          </div>
          <div className='image'>
            <div className="image-cnt">
              <img src={backgroundImage ? backgroundImage : bath_1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}