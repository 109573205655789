import { mapMediaUrlToPublicFolder, parseMultilineToArray, safeArray } from './utils';

const shouldUseBackgroundImageByIndex = (index) => {
  const useBackgroundIndices = [0,1];
  return useBackgroundIndices.includes(index);
}

// top section props
export const prepareTopSectionProps = topSection => {
  if (!topSection) return {};
  return {
    title: topSection.title,
    subtitle: topSection.subtitle,
    listTitle: topSection.points_title || '',
    listItems: topSection.points_paragraph_list,
    backgroundImage: mapMediaUrlToPublicFolder(topSection.media_background?.[0]),
  }
}

// how it works props
export const prepareHowItWorksSectionProps = howItWorksSection => {
  if (!howItWorksSection) return [];
  return safeArray(howItWorksSection.points_paragraph_list).map((paragraph, index) => {
    const title = howItWorksSection.points_subtitle_list?.length ? howItWorksSection.points_subtitle_list[index] : "";
    const image = howItWorksSection.media_assets_list?.length ? howItWorksSection.media_assets_list[index] : "";
    return ({
      title,
      text: paragraph,
      useBackgroundImage: shouldUseBackgroundImageByIndex(index),
      imgProps: {
        src: mapMediaUrlToPublicFolder(image),
      }
    })
  })
}

// call to action section props
export const prepareCallToActionSectionProps = callToActionSection => {
  if (!callToActionSection) return {};
  return (
    {
      title: callToActionSection.title,
      text: callToActionSection.paragraph,
      subtitle: callToActionSection.subtitle,
      guaranteeItems: callToActionSection.points_paragraph_list,
      backgroundImage: mapMediaUrlToPublicFolder(callToActionSection.media_background?.[0]),
    }
  )
}

// google reviews section props
export const prepareGoogleReviewsSectionProps = googleReviewsSection => {
  if (!googleReviewsSection) return [];
  return safeArray(googleReviewsSection.points_paragraph_list).map((reviewText, index) => {
    const name = googleReviewsSection.points_subtitle_list?.length ? googleReviewsSection.points_subtitle_list[index] : "";
    const photo = googleReviewsSection.media_assets_list?.length ? googleReviewsSection.media_assets_list[index] : "";
    return ({
      text: reviewText,
      name: name,
      photo: mapMediaUrlToPublicFolder(photo),
    })
  })
}

export const prepareOurLatestWorkSectionProps = ourLatestWorkSection => {
  if (!ourLatestWorkSection) return [];
  return safeArray(ourLatestWorkSection.media_assets_list).map(mediaAsset => mapMediaUrlToPublicFolder(mediaAsset));
}

// pipeline is our algorithm to map parsed object from sheets
// to props for page component, which will pass this props
// further down to child components, and they will render content
export const homePagePipeline = sheetsHomePage => {
  const topSection = sheetsHomePage['TOP SECTION'] ?? {};
  const callToActionSection = sheetsHomePage['CALL TO ACTION SECTION'] ?? {};
  const googleReviewSection = sheetsHomePage['Google Reviews'] ?? {};
  const latestWorkSection = sheetsHomePage['Our Latest Work'] ?? {};
  const howItWorksSection = sheetsHomePage['How It Works SECTION'] ?? {};
  const readyToGetStartedSection = sheetsHomePage['Ready To Get Started?'] ?? {};
  const footerSection = sheetsHomePage['Footer SECTION'] ?? {};

  const topSectionProps = prepareTopSectionProps(topSection);

  const howItWorksSectionProps = {
    items: prepareHowItWorksSectionProps(howItWorksSection),
  };

  const callToActionSectionProps = prepareCallToActionSectionProps(readyToGetStartedSection);

  const googleReviewsSectionProps = {
    items: prepareGoogleReviewsSectionProps(googleReviewSection),
    title: googleReviewSection.points_title,
  }

  const introSectionProps = {
    title: callToActionSection.title,
    subtitle: callToActionSection.subtitle,
    text: callToActionSection.paragraph,
  }

  const latestWorkSectionProps = {
    title: latestWorkSection.title,
    items: prepareOurLatestWorkSectionProps(latestWorkSection)
  }

  const footerSectionProps = {
    text: footerSection.paragraph,
  }

  return ({
    topSectionProps,
    howItWorksSectionProps,
    callToActionSectionProps,
    googleReviewsSectionProps,
    introSectionProps,
    latestWorkSectionProps,
    footerSectionProps,
  })
}