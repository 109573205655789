import * as Yup from 'yup';

export const GENERIC_VALIDATE = /^([a-zA-Z-]+\s)*[a-zA-Z-]+$/;
export const GENERIC_EMAIL_VALIDATE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const GENERIC_PHONE_VALIDATE = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const GENERIC_MIN = 2;
export const GENERIC_MAX = 35;

export const firstNameValidation = Yup.string()
  .matches(GENERIC_VALIDATE, 'Accepts only uppercase, lowercase characters and hyphens.')
  .min(GENERIC_MIN, `Minimum ${GENERIC_MIN} characters`)
  .max(GENERIC_MAX, `Maximum ${GENERIC_MAX} characters`)
  .required('Field is required');
// can be changed in future
export const lastNameValidation = firstNameValidation;

// can be changed in future
export const positionApplyValidation = firstNameValidation;

export const cityValidation = Yup.string()
  .matches(GENERIC_VALIDATE, 'Accepts only uppercase, lowercase characters and hyphens.')
  .min(GENERIC_MIN, `Minimum ${GENERIC_MIN} characters`)
  .max(GENERIC_MAX, `Maximum ${GENERIC_MAX} characters`);

export const emailValidation = Yup.string()
  .matches(GENERIC_EMAIL_VALIDATE, 'Please enter valid email address')
  .required('Field is required');

export const phoneValidation = Yup.string()
  .matches(GENERIC_PHONE_VALIDATE, 'Please enter valid phone number');