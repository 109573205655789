import React from 'react';

import { Link } from "react-router-dom";
import withFormattedText from '../../Common/withFormattedText';

const _CallToAction = (props) => {
  const { text, className, title = 'Get Your Quote', backgroundImage = "", isKitchenApp } = props;

  let region = '';
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = ''
  }

  const renderButton = () =>{
    // const isKitchenApp = process.env.REACT_APP_NAME !== 'bathrooms';
    // if(region==='vancouver' || region ==='calgary'){
    if(isKitchenApp || true) {
      return <Link to="/get-started" className="blue-btn margin-auto">Get Started</Link>
    } else {
      return <a href="/design" className="blue-btn margin-auto-mobile">GET STARTED</a>
    }
  }

  return (
    <section className={`about-section-3 ${className || ''}`} style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}}>
      <div className="container">
        <div className="cnt">
          <h3>
            <span>{title}</span>
          </h3>
          <p>
            <span className='call-to-action_text'>{text}</span>
          </p>
          {renderButton()}
        </div>
      </div>
    </section>
  )
}

export default _CallToAction;
export const CallToAction = withFormattedText(_CallToAction, ['text'])