import { mapMediaUrlToPublicFolder } from './utils';
// prepare props for sections on "ABOUT US" page (reused in flooring and painting for now)

// top section props
export const prepareTopSectionProps = topSection => {
  if (!topSection) return {};
  return ({
    title: topSection.title,
    subtitle: topSection.subtitle,
  })
}

// who are we section props
export const prepareWhoAreWeSection = whoAreWeSection => {
  if (!whoAreWeSection) return {};
  return ({
    title: whoAreWeSection.title,
    text: whoAreWeSection.paragraph,
  })
}

// guarantees section props
export const prepareGuaranteesSectionProps = guaranteesSection => {
  if (!guaranteesSection) return {};
  return ({
    title: guaranteesSection.title,
    text: guaranteesSection.paragraph,
  })
}

// our values section props
export const prepareOurValuesSectionProps = ourValuesSection => {
  if (!ourValuesSection) return {};
  return ({
    title: ourValuesSection.points_title,
    values: ourValuesSection.points_paragraph_list
  })
}

// experience section props
export const prepareExperienceSectionProps = experienceSection => {
  if (!experienceSection) return {};
  return ({
    title: experienceSection.title,
    subtitle: experienceSection.subtitle,
    text: experienceSection.paragraph,
  })
}

// pipeline is our algorithm to map parsed object from sheets
// to props for HomePage component, which will pass this props
// further down to child components, and they will render content
export const aboutUsPipeline = aboutUsPage => {
  const topSection = aboutUsPage['TOP SECTION'] ?? {};
  const contactSection = aboutUsPage['CONTACT SECTION'] ?? {};
  const whoAreWeSection = aboutUsPage['WHO ARE WE SECTION'] ?? {};
  const guaranteesSection = aboutUsPage['GUARANTEES SECTION'] ?? {};
  const valuesSection = aboutUsPage['VALUES SECTION'] ?? {};
  const experienceSection = aboutUsPage['EXPERIENCE SECTION'] ?? {};
  const quoteSection = aboutUsPage['QUOTE SECTION'] ?? {};
  
  const callToActionSectionProps = {
    title: quoteSection.title,
    text: quoteSection.paragraph,
    backgroundImage: mapMediaUrlToPublicFolder(quoteSection.media_background?.[0]),
  }

  const contentSectionProps = prepareWhoAreWeSection(whoAreWeSection);

  const topSectionProps = prepareTopSectionProps(topSection);

  const whoWeAreSectionProps = prepareGuaranteesSectionProps(guaranteesSection);

  const ourValuesSectionProps = prepareOurValuesSectionProps(valuesSection)

  const experienceSectionProps = prepareExperienceSectionProps(experienceSection);

  return ({
    topSectionProps,
    contentSectionProps,
    whoWeAreSectionProps,
    ourValuesSectionProps,
    experienceSectionProps,
    callToActionSectionProps,
    contactSectionProps: contactSection,
  })
}