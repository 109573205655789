import React, { useState, useEffect, useRef } from 'react';
import loadSpreadsheetPage, { parseContentsForPage, parseGenericValuesObject } from './getPageContents';

let contentsForPages;
export let contentsCache = {};
let loadingPromise;

export const generateContentsGenericObject = async (documentId) => {
  try {
    const worksheets = await loadSpreadsheetPage(documentId);
    if (!worksheets) throw new Error("Contents can't be empty in spreadsheets");
    const genericObjectWithContents = {};
    const tabs = Object.keys(worksheets);
    await Promise.all(tabs.map(tab => parseGenericValuesObject(worksheets[tab]).then(parsedContents => genericObjectWithContents[tab] = parsedContents)));
    // console.log('generic object', genericObjectWithContents);
    return genericObjectWithContents;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const loadContentsForPages = async (documentId) => {
  let promise = loadingPromise ? loadingPromise : (loadingPromise = generateContentsGenericObject(documentId));
  const contents = await promise;
  contentsForPages = contents;
  if (!Object.keys(contentsCache).length) {
    const mappedObjectWithContentsProps = {};
    Object.keys(contentsForPages).forEach(pageName => {
      mappedObjectWithContentsProps[pageName] = parseContentsForPage(contentsForPages[pageName], pageName);
    })
    contentsCache = mappedObjectWithContentsProps;
    // console.log('mapped object', mappedObjectWithContentsProps);
  }
  return contentsCache;
};

// Higher Order Component
// It wraps page component, waits for data to load from google docs spreadsheets
// caches that data, and when it's ready passes this data as "content" prop to a page
// sheetName: name of google.docs spreadshets tab
// documentId: id of google.docs document
export const withLoadableContents = (Component, {
  sheetName,
  documentId,
}) => {
  return function _AwaitsContents(props) {
    const [contents, setContents] = useState(contentsCache?.[sheetName] || null);
    // equals true when component is unmounted.
    // used to prevent setState on unmounted component
    const isUnmountedRef = useRef(false);
    useEffect(() => {
      const handleLoadContentsIfNotLoadedYet = async () => {
        if (contentsCache[sheetName] && !isUnmountedRef.current) {
          return setContents(contentsCache[sheetName]);
        }
        if (contentsForPages && !isUnmountedRef.current) {
          // here we map generic object with contents parsed from spreadsheets
          // to props that our page needs
          // maybe add possibility to specify custom map pipeline
          // on route level for customization purposes
          const parsedContent = parseContentsForPage(contentsForPages[sheetName], sheetName);
          contentsCache[sheetName] = parsedContent;
          return setContents(contentsCache[sheetName]);
        }
        try {
          const cache = await loadContentsForPages(documentId);
          if (isUnmountedRef.current) return;
          setContents(cache[sheetName]);
        } catch (error) {
          console.error(`Error loading contents for a page: ${error}`);
        }
      }
      handleLoadContentsIfNotLoadedYet();

      return () => {
        isUnmountedRef.current = true;
      }
    }, []);
    if (!contents) return null;
    return (
      <Component content={contents} {...props} />
    )
  };
};