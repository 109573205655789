import React from 'react';
import { Link } from "react-router-dom";
import blueCheckmark from "../../Assets/images/checkmark-blue.png";

const DEFAULT_GUARANTEE_ITEMS = [
  'Convenience From the Comfort of Your Home',
  'Expert Installation Team with 20+ Years of Experience',
  'Guaranteed Three-Week Installation',
  'Quoted price Guaranteed - NO Overages',
  '5-Year Unlimited Warranty',
]

export const CallToAction = (props) => {

  const { text, title = 'Ready to get started?', guaranteeItems = DEFAULT_GUARANTEE_ITEMS, className = '', subtitle, siteName = 'bathroom', backgroundImage } = props;

  const desc = (
    <>
      Try our <u className="blue">3D Creator</u> and get your new {siteName} designed and built just for you from
      the comfort of your home.
    </>
  )

    return (
      <section className={`home-start ready ${className}`} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="d-flex checkmark-box container">
          <div className="cnt cnt__list">
            <h2>{title}</h2>
            <p className="weight400">{subtitle}</p>
            <ul className="checkmark-list">
              {
                guaranteeItems.map((item, index) => (
                  <li key={index}>
                    <img src={blueCheckmark} alt="check mark icon" className="float-left" /><b className='checkmark-list__text'>{item}</b>
                  </li>
                ))
              }

            </ul>
            {/* <p>{desc}</p> */} 
          </div>
          <div className="cnt centered-btn">
            {/* <a href="http://bathroomrenovations.com/design" className="blue-btn desktop-btn">
              GET STARTED
            </a> */}
              <Link to="/get-started" className="blue-btn desktop-btn btn-no-wrap">
               {/* { props.isDesign3D && <p>Call {phone} today to schedule your free consultation or get started with our 3D Online Kitchen Creator Tool Today!</p>} */}
                Get Free Quote
              </Link>
          </div>
        </div>
      </section>
    )
}