import React from 'react';

export const HowItWorks = ({ items = [], className = '' }) => {

  return (  
    <section className={`home-hiw ${className}`}>
      <div className="container">
        <h2>How It Works</h2>
        <ul className='how-it-works-list'>
          {items.map((item, index) => (
            <li className='how-it-works-list_item' key={index} data-number={index + 1}>
              {!item.useBackgroundImage && (
                <div>
                  <img alt='' {...item.imgProps} />
                </div>
              )}
              <h3>
                {item.useBackgroundImage && <span style={{ backgroundImage: `url(${item.imgProps.src})` }} className="item-image" />}
                {item.title}
              </h3>
              <p>{item.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
