import React from 'react';

import { 
  TopSection,
  Content,
  ContactInfo,
  WhoWeAre,
  OurValues,
  Experience,
  CallToAction,
} from '../../Containers/About'

export const AboutUsPage = (props) => {

  let phone;
  let email;
  let locations;

  const { content } = props;

  const {
    topSectionProps = {},
    contentSectionProps = {},
    whoWeAreSectionProps = {},
    ourValuesSectionProps = {},
    experienceSectionProps = {},
    callToActionSectionProps = {},
    contactSectionProps = {},
  } = content;

  if (props.data) {
    if (props.isKitchenApp) {
      phone = props.data.kitchen_phone;
    } else {
      phone = props.data.bathroom_phone;
    }

    email = props.data.email;
    locations = props.data.location;
  }

  let region = '';
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = ''
  }

  let dynamicCallToActionSectionProps = callToActionSectionProps;

  if (region==='vancouver' || region==='calgary') {
    dynamicCallToActionSectionProps = {
      ...callToActionSectionProps,
      text: "To find out how decades of trusted industry experience canhelp your bathroom renovation project take flight, call or reach out online today.",
    }
  }

  /*
  const contentData = {
    title: whoAreWeSection.title, //"The #1 Bathroom Design & Renovation Experts",
    text:"We provide turnkey design/built bathroom renovation packages. Since 1998, AGM Renovations has created tens of thousands of unique bathrooms. As highest rated renovators, we’ve long been recognized for our quality craftsmanship, customer service, and our team of experts who can turn your old bathroom into a spa oasis."
  }
  */

  const { isKitchenApp } = props;

  const extendedCallToActionSectionProps = {
    ...dynamicCallToActionSectionProps,
    className: 'bathrooms__about-us-call-to-action',
  }

  return (
    <>
      <TopSection {...topSectionProps} />
      <ContactInfo
        {...contactSectionProps}
        phone={phone}
        email={email}
        locations={locations}
      />
      <Content {...contentSectionProps} />
      <WhoWeAre {...whoWeAreSectionProps} />
      <OurValues {...ourValuesSectionProps} />
      <Experience {...experienceSectionProps} />
      <CallToAction isKitchenApp={isKitchenApp} {...extendedCallToActionSectionProps} />
    </>
  );
}