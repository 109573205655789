import React from 'react';

import {
  TopSection,
  WorksGallery,
  Intro,
  CallToAction,
} from '../../Containers/OurWork'
import { prepareCallToActionProps, prepareTopSectionProps } from '../ourWorkPageSectionProps';

export const OurWorkPage = (props) => {
  const { content } = props;

  const {
    topSectionProps = {},
    introSectionProps = {},
    workGallerySectionProps = {}
  } = content;

  let phone, email, locations;

  const importAll = (r) => {
    return r.keys().map(r);
  }

  const galleryImages = importAll(
    require.context(
      "../../Assets/images/BathroomGallery/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  /*
  const topSectionProps = {
    title: topSection.title || '',
    text: 'From spacious master ensuites to small compact condo bathrooms, our recently finished projects speak for themselves.',   
  }
  */

  if (props.data) {
    if (props.isKitchenApp) {
      phone = props.data.kitchen_phone;  
    } else {
      phone = props.data.bathroom_phone;
    }
    
    email = props.data.email;
    locations = props.data.location;
  }
  
  const CalltoAction = {
    title: "Your Dream Bathroom is Only a Phone Call Away",
    text: <>
        We’ve helped countless Greater Toronto homeowners improve their bathrooms over the years – are you next? Call <a href='tel:{phone}'>{phone}</a> today to discover how AGM can help turn your old, outdated bathroom into the modern design of your dreams.",
       </>,
    phone: phone
  }

  /*
  const introData = {
    title: callToActionSection.title || '',
    desc: <>
      Try our <u className="blue">3D Creator</u> and get your new bathroom design and quote today.
      </>
    }
    */

      //* Get started using our 3D Creator or call <a href='tel:{phone}'>{phone}</a> to get a free quote
  // keep old images in case there is no gallery images for specific website version
  // TODO: can be removed as soon as we have gallery for each website
  const dynamicWorkGallerySectionProps = { ...workGallerySectionProps };
  if (!dynamicWorkGallerySectionProps.images?.length) {
    dynamicWorkGallerySectionProps.images = galleryImages;
  }

  return (
    <>
      <TopSection {...topSectionProps} />
      <WorksGallery {...dynamicWorkGallerySectionProps} />
      <Intro {...introSectionProps} />
      {/* <CallToAction {...CalltoAction} /> */}
    </>
  )
}