export const DEFAULT_EXTENSIONS = ['txt', 'doc', 'docx', 'pdf', 'docs'];
// value is in bytes. 10485760 bytes equals 10 megabytes.
export const MAX_SIZE = 10485760;

export const DEFAULT_OPTIONS = {
  extensions: DEFAULT_EXTENSIONS
}

export const getFileExtension = filename => filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;

export default function (file, { extensions, maxSize } = DEFAULT_OPTIONS) {
  let errorMsg = "";
  if (!file) return "File is undefined";
  const { size, name } = file;
  const fileExtension = getFileExtension(name);

  if (!extensions.includes(fileExtension)) {
    errorMsg = `Only ${extensions.join(', ')} allowed as file's extension.`;
  }
  else if (size > maxSize) {
    errorMsg = `File's size should not exceed ${Math.floor(maxSize / 1000000)}`;
  }

  return errorMsg;
}